import React from "react";
import Button from "./Button";


function Contact({qrNumber}) {
    return (
        <section className="contact">
            <header>
                <h4>Contacteer ons via :</h4>
            </header>
            <article className='flex flex-column flex-center flex-space-evenly'>
                <div className='flex flex-center flex-space-evenly'>
                    <Button link="mailto:help@netflow.be"
                            text='Mail ons'/>
                    <Button link="tel:+32 3 535 60 60"
                            text='Bel ons'/>
                </div>
                {qrNumber !== null && qrNumber !== '' ? (
                    <div className='flex flex-center'>
                        <Button link={`http://lansweeper.vestaterminals.com:81/asset.aspx?assetid=` + qrNumber}
                                text='Lansweeper'
                        />
                    </div>) : null
                }
            </article>
        </section>
    );
}

export default Contact;
