import {getPngSet, getWebSet} from "./assets/images/logoPng.js";
import fallback from "./assets/images/png/vesta-logo-512.png";
import "./css/App.scss";
import "./css/fonts.css";

import Contact from "./components/Contact";
import QrCode from "./components/QrCode";
import Footer from "./components/Footer";
import DarkModeToggle from "./components/DarkModeToggle";
import {useState} from "react";


function App() {
    const webSet = getWebSet();
    const pngSet = getPngSet();


    const [qrNumber, setQrNumber] = useState(null);

    const handleQrScan = (number) => {
        setQrNumber(number);
    }

    return (
        <section className="app">
            <header>
                <DarkModeToggle/>
            </header>
            <main>
                <header className="app-header">
                    <picture>
                        <source srcSet={webSet} type="image/webp"/>
                        <source srcSet={pngSet} type="image/png"/>
                        <img src={fallback} className="app-logo" alt="logo"/>
                        {/*<img src={logo} className="app-logo" alt="logo"/>*/}
                    </picture>
                    <h1>Vesta Terminals</h1>
                </header>

                <QrCode onScan={handleQrScan}/>
                <h3>dit toestel is beheerd door Netflow,<br/>problemen met dit toestel?</h3>
                <Contact qrNumber={qrNumber}/>
            </main>
            <Footer/>
        </section>
    );
}

export default App;
