function Address() {
    return (
        <article>
            <p>Burchtstraat 190</p>
            <p>9150 Kruibeke</p>
            <p>België</p>
        </article>
    );
}

export default Address;
