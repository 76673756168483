import logoPng192 from './png/vesta-logo-192.png'
import logoPng512 from './png/vesta-logo-512.png'
import logoWebp192 from './webp/vesta-logo-192.webp'
import logoWebp512 from './webp/vesta-logo-512.webp'

const logosPng = [
    logoPng512,
    logoPng192,
]
export const getPngSet = () => {
    let pngSet = '';

    logosPng.forEach((logo, index) => {
        pngSet += `${logo} ${index + 1}x, `;
    });

    /* Remove the trailing comma and space */
    return pngSet.slice(0, -2);
}


const logosWebp = [
    logoWebp512,
    logoWebp192,
]
export const getWebSet = () => {
    let pngSet = '';

    logosWebp.forEach((logo, index) => {
        pngSet += `${logo} ${index + 1}x, `;
    });

    /* Remove the trailing comma and space */
    return pngSet.slice(0, -2);
}
export {logosPng, logosWebp};

