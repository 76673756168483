import Address from "./Address";
import React from "react";

function Footer() {
    return (
        <footer>
            <p>© 2023 Vesta</p>
            <Address/>
        </footer>
    );
}

export default Footer;
