import React from "react";

function Button({link, text, image = null}) {

    return image ?
        <a className="button-orange" href={link}>
            <img src={image} alt={text}/>
        </a> :
        <a className="button-orange" href={link}>{text}</a>;
}

export default Button;
