import React from "react";
import "react-toggle/style.css";

function DarkModeToggle() {

    const setDarkMode = () => {
        document.querySelector('body').setAttribute('data-theme', 'dark');
        localStorage.setItem('theme', 'dark');
    }

    const setLightMode = () => {
        document.querySelector('body').setAttribute('data-theme', 'light');
        localStorage.setItem('theme', 'light');
    }

    const theme = localStorage.getItem('theme');

    if (theme === 'dark')
        setDarkMode();
    else setLightMode();


    const toggleTheme = e => {
        if (e.target.checked)
            setDarkMode();
        else
            setLightMode();
    }
    return (
        <div className='dark-mode'>
            <input
                type='checkbox'
                id='toggle-dark-mode'
                onChange={toggleTheme}
                defaultChecked={theme === 'dark'}
                className='toggle'
            />
            <label htmlFor="toggle-dark-mode" aria-label='toggle dark mode'></label>
        </div>
    );
}

export default DarkModeToggle;
