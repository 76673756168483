import {useEffect, useState} from "react";

function QrCode({onScan}) {
    const [qrNumber, setQrNumber] = useState(null);

    const handleScan = (data) => {
        const url = new URL(data);

        /* Extract the number directly from the path, query, or any part of the URL */
        const pathSegments = url.pathname.split("/");
        const number = pathSegments[pathSegments.length - 1] ?? 0;

        setQrNumber(number);
        onScan(number);
    };

    useEffect(() => {
        const simulatedQRCodeResult = window.location.href;
        handleScan(simulatedQRCodeResult);
    }, []);


    const text = qrNumber ? `QR-tag # ${qrNumber}` : 'geen unieke tag gevonden';
    return (<p>{text}</p>)
}

export default QrCode;
